import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutGuard } from './services/logout.guard';
import { AuthGuard } from './services/auth.guard';
import { ForgotLockcodeComponent } from './forgot-lockcode/forgot-lockcode.component';

const routes: Routes = [
  	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},

	{
		path: 'login',
		component: LoginComponent,
		canActivate:[LogoutGuard]
	},

	{
		path: 'landing',
		loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
		
	},
	{
		path: 'forgot-password',
		component:ForgotPasswordComponent
	},
	{
		path: 'forgot-lockcode',
		component:ForgotLockcodeComponent
	},
	{
		path: '**',
		redirectTo: 'landing',
		pathMatch: 'full'
	},

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
