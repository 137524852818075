<div class="container">
	<div class="row" *ngIf="!emailSent">
		<div class="col-md-12 d-flex justify-content-center">
			<div class="box-container">
				<div class="box-header">
					<div>
						<h1 class="text-light fs-4">
							Forgot Your Lockcode ?
						</h1>
					</div>
					<div style="cursor: pointer;">
						<i class="bi bi-x-circle fs-4" [routerLink]="['/login']"></i>
					</div>
				</div>

				<!-- Styling Line after header -->
				<div class="box-styling-line"></div>

				<!-- Message after styling line -->
				<div class="box-message-container">
					<div class="box-message-text">
						<div class="msg-1 mt-2">
							<span class="text-light fs-6">
								Let us help you
							</span>
						</div>
						<br class="">
						<div class="msg-2 m-0">
							<span class="text-light fs-6">
								To protect your account, an automatic email will be send to your specified email
								containing the validation code.
							</span>
						</div>
					</div>
				</div>

				<!-- Email Input -->
				<div class="box-email-input-container">
					<div class="box-email-input d-flex align-items-center">
						<label class="form-label mx-4 text-light"> Email: </label>
						<input class="form-control mx-2" type="email" [(ngModel)]="email" name="email"
						(keydown.space)="$event.preventDefault()" (keyup.enter)="sendEmail()">
					</div>
				</div>


				<!-- Button -->
				<div class="row">
					<div class="col-md-12 text-center">
						<button class="btn add-btn fs-5" (click)="sendEmail()">
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>







	<!-- After entering email and clicking submit -->
	<div class="row" *ngIf="emailSent">
		<div class="col-md-12 d-flex justify-content-center align-content-middle">
			<div class="box-container">
				<!-- Header Message and X-close button -->
				<div class="box-header">
					<div>
						<h1 class="text-light fs-4">
							Forgot Your Lockcode ?
						</h1>
					</div>

					<!-- X-mark for closing -->
					<div style="cursor: pointer;">
						<i class="bi bi-x-circle fs-4" [routerLink]="['/login']"></i>
					</div>
				</div>

				<!-- Styling Line after header -->
				<div class="box-styling-line"></div>

				<!-- Message after styling line -->
				<div class="box-message-container">
					<div class="box-message-text">
						<!-- Message - 1 -->
						<div class="msg-1 mt-2">
							<span class="text-light fs-6">
								Let us help you
							</span>
						</div>

						<br>

						<!-- Message - 2 -->
						<div class="msg-2 m-0">
							<span class="text-light fs-6">
								An automatic email has been send to
								<i>
									{{ email.slice(0, 2) }}*****{{ email.replace(email.slice(0, email.indexOf("@") - 3),"*") }}
								</i>
								with the validation code. Please enter the validation code with your new lockcode.
							</span>
						</div>
					</div>
				</div>

				<!-- Code Input -->
				<div class="row box-email-input-container">
					<div class="col-md-12 d-flex align-items-center box-email-input">
						<div class="col-4">
							<label class="form-label mx-4 text-light"> Code: </label>
						</div>
						<div class="col-8">
							<input class="form-control mx-2" type="text" [(ngModel)]="code" name="code"
							(keydown.space)="$event.preventDefault()"  maxlength="6">
						</div>
					</div>
				</div>

				<!-- lockcode Input -->
				<div class="row box-email-input-container">
					<div class="col-md-12 d-flex align-items-center box-email-input">
						<div class="col-4">
							<label class="form-label mx-4 text-light"> Lockcode: </label>
						</div>
						<div class="col-8">
							<input class="form-control mx-2" type="text" [(ngModel)]="lockcode" name="lockcode"
							(keydown.space)="$event.preventDefault()" maxlength="4" onkeypress="return /[0-9]/.test(event.key)">
						</div>
					</div>
				</div>

				<!-- Confirm lockcode Input -->
				<div class="row box-email-input-container">
					<div class="col-md-12 d-flex align-items-center box-email-input">
						<div class="col-4">
							<label class="form-label mx-4 text-light"> Confirm lockcode: </label>
						</div>
						<div class="col-8">
							<input class="form-control mx-2" type="text" [(ngModel)]="c_lockcode" name="c_lockcode"
							(keydown.space)="$event.preventDefault()" maxlength="4" onkeypress="return /[0-9]/.test(event.key)">
						</div>
					</div>
				</div>



				<!-- Button -->
				<div class="row">
					<div class="col-md-12 text-center">
						<button class="btn add-btn fs-5" (click)="resetLockcode()">
							Submit
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid">
	<div class="row">
		<div class="footer col-md-12 float-start">
			<img class="footer-triangle" src="../../assets/footer-triangle.png">
			<!-- <div class="addButton" (click) = "addEditForm = true"></div> -->
			<div class="fixed-bottom"></div>
			<div class="col">
			</div>
		</div>
	</div>
</div>
