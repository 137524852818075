import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }
  
  ngOnInit(): void {
  }

  home() {
    let check = localStorage.getItem('redirect')

    //for Trackialt logo to work accordingly where to navigate
    switch (check) {
      case 'rfidscanner': {
        console.log("in rfidscanner")
        this.router.navigate(['/landing/rfidscanner'])
        break;
      }
      case 'redirectout': {
        console.log("in redirectout")
        this.router.navigate(['/landing/lockcode'])
        break;
      }
      case 'dashboardIn': {
        console.log("in dashboardin")
        localStorage.setItem('redirect', 'dashboard')
        this.router.navigate(['/landing/dashboard'])
        break;
      }
      default: {
        localStorage.removeItem('redirect')
        this.router.navigate(['/landing'])
        break;
      }
    }
  }
}
