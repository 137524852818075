import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ApiService } from './api.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private api: ApiService,
    private router: Router
  ) { }

  canActivate() {
    let check = localStorage.getItem('redirect')
    if (this.api.isLoggedIn()) {
      if (check) {
        if(check=='dashboardIn'){
          console.log("in dashboard");
          
          this.router.navigate(['/landing/dashboard'])
        }
        console.log('inside if of landing');
        check == 'rfidscanner' ? this.router.navigate(['/landing/rfidscanner']) : check == 'redirectout' ? this.router.navigate(['/landing/lockcode']) : this.router.navigate(['/landing/lockcode']);
      } else {
        console.log('inside else of landing');

      }
      ///this.router.navigate(['/dashboard']);
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}