import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{

	email: any;
	password: any;
	user_name: any;		// set from localStorage
	isChecked: boolean = true
	is_logged_in: boolean = false;
	show_password: boolean
	constructor(
		private api: ApiService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) { }

	ngOnInit(): void
	{
	}

	login()
	{
		this.spinner.show()
		if (!this.email || !this.password)
		{
			this.toastr.error('All Fields are required. ', 'Error!');
			// this.spinner.hide()
		} else
		{
			let data = {
				email: this.email,
				password: this.password
			}
			console.log("data to be send", data)
			this.api.loginUser(data).then((res: any) =>
			{
				console.log("response getting ----[]", res)
				if (res.status == 200 && res.data.role == "teacher")
				{
					console.log(res)	// response data - testing
					this.is_logged_in = true;
					localStorage.setItem('userData',JSON.stringify(res.data))
					localStorage.setItem('role', res.data.role)
					localStorage.setItem('_id', res.data._id)
					localStorage.setItem('allowToAddStudent', res.data.isAllowToAddStudent)
					localStorage.setItem('allowToDisableStudent', res.data.isAllowToDisableStudent)
					localStorage.setItem('allowToEditRifd', res.data.isAllowToEditRfid)
					this.user_name = `${res.data.firstName.charAt(0).toUpperCase()}${res.data.firstName.slice(1)} ${res.data.lastName.charAt(0).toUpperCase()}${res.data.lastName.slice(1)}`
					localStorage.setItem('user_name', this.user_name)
					localStorage.setItem('schoolName', res.data.schoolId[0].schoolName)
					this.api.setAccessToken(res.data.accessToken) // store access token

					this.toastr.success(`Welcome back ${this.user_name}. `, "Success")
					this.router.navigate(['/landing'])
					this.spinner.hide()
				}
				else if (res.status != 200)
				{
					this.toastr.error(res.message, "Error !")
				}
				else
				{
					this.toastr.error("Authorization Error !", "Error !")
				}
				this.spinner.hide()
			})
		}
		this.spinner.hide()
	}

}
