import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'

@Injectable({
	providedIn: 'root'
})
export class ApiService
{

	getDistrict(_id: any)
	{
		return this.userData
		throw new Error('Method not implemented.');
	}
	getSchoolByDistrict(_id: any, arg1: string[])
	{
		return this.userData
		throw new Error('Method not implemented.');
	}
	getTeacherByMultipleSchool(schoolId: any[])
	{
		return this.userData
		throw new Error('Method not implemented.');
	}


	authToken: any = localStorage.getItem('token')
	baseURL: string = environment.API_SERVICE_URL
	userObject: any;

	constructor(private http: HttpClient) { }

	// --------------------------------------------------------------
	// 							Authentication
	// --------------------------------------------------------------

	get userData()
	{
		console.log(this.userObject);

		if (this.userObject)
		{
			return this.userObject;
		}
		else
		{
			return JSON.parse(localStorage.getItem('userData'))
		}
	}

	getAccessToken()
	{
		if (localStorage.getItem('token'))
		{
			this.authToken = localStorage.getItem('token')

		}
		return localStorage.getItem('token')
	}

	setAccessToken(token: string)
	{
		localStorage.setItem('token', token)
		console.log("User Token saved. ")
	}

	async loginUser(data: any)
	{
		console.log("Request Received...")
		return await this.http.post(this.baseURL + 'user/login', data)
			.toPromise()
			.then(res => res)
	}

	logOut()
	{
		this.authToken = null
		localStorage.clear()
	}

	isLoggedIn()
	{
		console.log(this.getAccessToken() !== null)
		return this.getAccessToken() !== null
	}

	isLoggedOut()
	{
		return this.getAccessToken() === null
	}


	/**
	 * Forgot Password functionality
	 * @param data data to be sent to the service
	 * @returns object
	 */

	async forgotPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/forget-password', data)
			.toPromise()
			.then(res => res)
	}

	/**
	 *
	 * @param data data
	 * @returns object
	 */
	async resetPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/reset-password', data)
			.toPromise()
			.then(res => res)
	}

	// ---------------------------------------------------------------
	//                Profile
	// ---------------------------------------------------------------

	async getProfileInfo(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}user/getProfile/${id}`, headers)
			.toPromise()
	}

	async updateProfile(id: any, data: any)
	{
		console.log('AuthToken: ', this.authToken)
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.put(`${this.baseURL}user/updateProfile/${id}`, data, headers)
			.toPromise()
	}

	setName(name: string)
	{
		localStorage.setItem('user_name', name)
		console.log('User Name has been changed. ')
	}

	getName()
	{
		return localStorage.getItem('user_name')
	}

	updateLockCode(id: any, data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		console.log(headers)
		return this.http.post(this.baseURL + 'teacher/change-lock-code/' + id, data, headers)
			.toPromise()
			.then(res => res)
	}
	// --------------------------------------------------------------
	// 							Attendance
	// --------------------------------------------------------------

	getClassroom(id: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		console.log(headers)
		return this.http.get(`${this.baseURL}admin/classroom-By-Teacher/${id}`, headers)
			.toPromise()
	}

	getStudents(data: any)
	{
		// let headers: any = { headers: { Authorization: this.authToken } };
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		};
		console.log(headers)
		return this.http.get(`${this.baseURL}admin/student-list-attendance/`, headers)
			.toPromise()
			.then(res => res)
	}

	// getStudentsList(data: any)
	// {
	// 	let headers: any = {
	// 		params: data,
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	};
	// 	return this.http.get(this.baseURL + 'admin/student-list-attendance/', headers)
	// 	.toPromise()
	// 	.then(res=>res)
	// }

	addAttendance(teacherId: any, data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		console.log(headers)
		return this.http.post(`${this.baseURL}admin/add-attendance/${teacherId}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	// --------------------------------------------------------------
	//		 					Student
	// --------------------------------------------------------------

	getAllStudents(id: any, data?: any)
	{
		let headers: any = { params: data, headers: { Authorization: this.authToken } };
		console.log(id, data)
		return this.http.get(this.baseURL + 'teacher/student-list/' + id, headers)
			.toPromise()
			.then(res => res)
	}

	addStudent(id: any, data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(this.baseURL + 'teacher/add-student/' + id, data, headers)
			.toPromise()
			.then(res => res)
	}

	editStudent(id: any, data: any)
	{
		console.log(id, data)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.put(this.baseURL + 'teacher/update-student/' + id, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteStudent(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/student/${id}`, headers)
			.toPromise()
			.then(res => res)
	}

	atendanceMetric(id: any)
	{
		console.log(id)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL + 'user/attendance-metrics/' + id, headers)
			.toPromise()
	}

	verifyLockCode(id: any, data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(this.baseURL + 'teacher/verify-lock-code/' + id, data, headers)
			.toPromise()
			.then(res => res)
	}
	// --------------------------------------------------------------
	//		 					RfidLookup
	// --------------------------------------------------------------

	rfidSearch(id: any, data: any)
	{
		console.log("hi in rfid")
		let headers: any = { params: data, headers: { Authorization: this.authToken } };
		return this.http.get(`${this.baseURL}teacher/rfid-lookup/${id}`, headers)
			.toPromise()
			.then(res => res)
	}


	addAttendanceByRfidScanner(id: any, data: any)
	{
		console.log("hi in rfidscanner")
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(`${this.baseURL}teacher/attendance-by-scanner/${id}`,data, headers)
			.toPromise()
	}

	
	// --------------------------------------------------------------
	//		 					Reporting
	// --------------------------------------------------------------


	getAttendanceDetail(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}teacher/attendance-detail-report/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	getStudentReport(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}teacher/student-report/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	getSchoolReport(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}teacher/school-report/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}
	// --------------------------------------------------------------
	//		 					Misc
	// --------------------------------------------------------------

	getAllTeachers(schoolId: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(`${this.baseURL}teacher/get-all-teachers/${schoolId}`, headers)
			.toPromise()
			.then(res => res)
	}

	getAllStates()
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(this.baseURL + "super-admin/state", headers)
			.toPromise()
			.then(res => res)
	}

	getGradeByMultipleteacher(teacherId: any)
	{
		let teacher = teacherId.join(",")
		let headers: any = {
			params: {
				teacherId: teacher
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}teacher/grades-by-teacher`, headers)
			.toPromise()
			.then(res => res)

	}
	// getClassroom(teacherId:any,gradeId:any){
	// 	let teacher = teacherId.join(",")
	// 	let headers: any = {
	// 		params: {
	// 			teacherId: teacher
	// 		},
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return this.http.get(`${this.baseURL}teacher/grades-by-teacher`, headers)
	// 		.toPromise()
	// 		.then(res => res)

	// }

	resetLockcode(data:any){
		return  this.http.post(`${this.baseURL}teacher/forgot-lockCode`, data)
			.toPromise()
			.then(res => res)
	}


	getCarrierDomain(){
		return  this.http.get(`${this.baseURL}teacher/forgot-lockCode`)
			.toPromise()
			.then(res => res)
	}

	getCalendar(id:any){
		return  this.http.get(`${this.baseURL}admin/calendar-by-school/${id}`)
		.toPromise()
	}
}
