import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
//import { AuthGuard } from './services/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ApiService } from './services/api.service';
import { AuthGuard } from './services/auth.guard';
//import { RfidscannerGuard } from './services/rfidscanner.guard';
import { SharedModule } from './shared/shared.module';
import { ForgotLockcodeComponent } from './forgot-lockcode/forgot-lockcode.component';
//import { LandingComponent } from './landing/landing.component';
//import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
//import { AttendanceComponent } from './dashboard/attendance/attendance.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    ForgotLockcodeComponent,
    //LandingComponent
    //ForgotPasswordComponent,
    //AttendanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      timeOut: 2500,
      extendedTimeOut: 1000,
      closeButton: true
    }),    
  ],
  providers: [ApiService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
