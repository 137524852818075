import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit
{
  @Input() username: string = ""

  role: string
  user_name: string
  school_name: string | undefined

  constructor(
    private api: ApiService,
    private router: Router
  )
  {
    this.user_name = localStorage.getItem('user_name') ? `Welcome ${localStorage.getItem('user_name')} !` : ""
    this.role = localStorage.getItem('role')
    this.school_name = localStorage.getItem('schoolName') ? localStorage.getItem('schoolName') : ''
  }

  ngOnChanges(changes: SimpleChanges)
  {
    console.log(changes)
    if (changes.firstChange)
    {
      this.user_name = JSON.stringify(changes.currentValue)
    } else
    {
      this.user_name = localStorage.getItem('user_name') ? `Welcome ${localStorage.getItem('user_name')} !` : ""
    }
  }

  ngOnInit(): void
  { }


  logout()
  {
    this.api.logOut()
    this.router.navigate(['/login'])
  }

  profile()
  {
    this.router.navigate(['/landing/profile'])
  }

}
