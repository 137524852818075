<div class="container-fluid">
  <div class="row">
    <div class="footer col-md-12 float-start">
      <img class="footer-triangle" src="../../assets/footer-triangle.png">
      <!-- <div class="addButton" (click) = "addEditForm = true"></div> -->
      <div class="fixed-bottom">
        <div class="col-12 float-start">
          <div class="text-center" *ngIf="user_name">
            {{ user_name }}
            <span class="dropdown">
              <i class="bi bi-person-fill me-4 profile-icon float-end dropdown-toggle"
                data-bs-toggle="dropdown" aria-expanded="false" *ngIf="user_name"></i>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" (click)="profile()">Profile</a></li>
                <li><a class="dropdown-item" (click)="logout()">Log Out</a></li>
              </ul>
            </span>

            <br>
            {{ role | titlecase }}
            <br>
            {{ school_name | titlecase }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



