import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate
{
	constructor(
		private router: Router,
		private api: ApiService
	) { }

	canActivate()
	{
		let check = localStorage.getItem('redirect');
		if(this.api.isLoggedOut())
		{
			return true
		}
		else
		{
			// if(check == 'lockcode'){
			// 	console.log('inside logout',check);
			// 	this.router.navigate(['/landing/lockcode']);
				
			// 	return true;
			// }
			this.router.navigate(['/dashboard'])
			return false
		}
	}
}